export default {
	init: function(utils, Popin){

        this.handleVideo(utils, Popin)
        
        const body = document.querySelector('body')
        body.addEventListener('dom:updated', () => {
            this.handleVideo(utils, Popin)
        })

    },
    handleVideo: function(utils, Popin){
        
        const popinVideoEl = document.querySelector('.js-popin-video')
        if (popinVideoEl) {

            const popinVideoInstance = new Popin(popinVideoEl, {
                openBtnCSSSelector: '.js-popin-video-open',
                closeBtnCSSSelector: '.js-popin-video-close',
                popinBackCSSSelector: '.js-popin-video-back',
                popinContentCSSSelector: '.js-popin-video-content'
            })

            const openBtns = document.querySelectorAll('.js-popin-video-open');
            let currentVideoType = null;
            let iframeVideo = null;
            const iframeContainer = popinVideoEl.querySelector(`.js-popin-video-iframe`);

            /**
             * 
             * @param {HTMLElement} button 
             */
            const createVideoIframe = (button) => {
                const videoUrl = button.dataset.videoId;
                const videoType = button.dataset.videoType ? button.dataset.videoType : 'steamlike'

                if (videoUrl) {
                    
                    if (iframeContainer) {
                        
                        //remove previous iframe
                        iframeContainer.innerHTML = '';

                        // Create new iframe
                        const newIframe = document.createElement('iframe');
                        const videoIframUrl = `${videoUrl}&autoplay=1`
                        newIframe.setAttribute('src', videoIframUrl);
                        newIframe.setAttribute('allowfullscreen', 'true');
                        newIframe.setAttribute('allow', 'accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share')
                        newIframe.setAttribute('frameborder', '0');

                        // Add the new iframe to popin
                        iframeContainer.appendChild(newIframe);
                    }

                }
            }

            popinVideoInstance.on('closed', () => {
                iframeContainer.innerHTML = '';
                currentVideoType = null;
                utils.enableScroll()
            })

            popinVideoInstance.on('opened', (event) => {
                const videoClickedType = event.detail.clickedEl.dataset.videoType;
                currentVideoType = videoClickedType;

                createVideoIframe(event.detail.clickedEl)
            })

            
        }
    }
}