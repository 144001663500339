export default {
	init: function(Choices) {
		document.querySelectorAll('.js-multiSelect').forEach((elem, index) => {
			const choices = new Choices(elem, {
				searchEnabled: false,
				searchChoices: false,
				removeItemButton: true,
				placeholderValue: 'Sélectionner une valeur',
				classNames: {
					containerOuter: 'multiSelect',
					containerInner: 'multiSelect__inner',
					input: 'multiSelect__input',
					inputCloned: 'multiSelect__input--cloned',
					list: 'multiSelect__list',
					listItems: 'multiSelect__list--multiple',
					listSingle: 'multiSelect__list--single',
					listDropdown: 'multiSelect__list--dropdown',
					item: 'multiSelect__item',
					itemSelectable: 'multiSelect__item--selectable',
					itemDisabled: 'multiSelect__item--disabled',
					itemOption: 'multiSelect__item--choice',
					group: 'multiSelect__group',
					groupHeading : 'multiSelect__heading',
					button: 'multiSelect__button'
				},
				loadingText: 'Chargement...',
				noResultsText: 'Aucun résultat trouvé',
				noChoicesText: 'Aucun choix possible',
				itemSelectText: '', // Clique pour choisir
				uniqueItemText: 'Seulement une valeur peut être ajoutée',
				customAddItemText: 'Seulement les valeurs correspondantes à des conditions spécifiques peuvent être ajoutées',
				addItemText: (value) => {
					return `Tapez Entrée pour ajouter <b>"${value}"</b>`;
				},
				maxItemText: (maxItemCount) => {
					return `Seulement ${maxItemCount} valeurs peuvent être ajoutées`;
				},
			});
		})
	},
}
