export default {
	init: () => {
		
        // Read more buttons
        /////////////////////////////////////////////////////////////////////////////
        const buttons = document.querySelectorAll('.js-readMore-button')

        if (buttons.length) {
            buttons.forEach(button => {

                button.addEventListener('click', function(e){
                    e.preventDefault()

                    const readMoreEl = button.closest('.js-readMore')

                    if (readMoreEl) {
                        readMoreEl.classList.toggle('--active');
                    }

                    
                })

            })
        }

	}
}