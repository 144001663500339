export default {
    init: (Popin, gsap) => {
        
        // Popin comments
        /////////////////////////////////
        const popinEl = document.querySelector('.js-popin-share')
        if (popinEl) {

            const popinInstance = new Popin(popinEl, {
                openBtnCSSSelector: '.js-popin-share-open',
                closeBtnCSSSelector: '.js-popin-share-close',
                popinBackCSSSelector: '.js-popin-share-back',
                popinContentCSSSelector: '.js-popin-share-content'
            })

		}

        // Copy link
        /////////////////////////////////
        const copyLink = document.querySelector('.js-share-copy')
        const createCopyBox = (text, positionX, positionY) => {
            let div = document.createElement('div');
            div.textContent = text;
            div.className = 'copyBox';
            div.style.left = positionX + 'px';
            div.style.top = positionY + 'px';

            const tlEntrance = gsap.timeline({ paused: true, onComplete: () => { 
                gsap.fromTo(div, {scale: 1, y: 0, }, {scale: 0, y: -50, duration: 0.5, ease: "power2.out" })
                setTimeout(() => {
                    div.remove()
                }, 500);
            } })
            tlEntrance.from(div, {scale: 0, duration: 0.8, ease: "elastic.out(1.5,0.5)" })
            
            document.body.appendChild(div);

            tlEntrance.play();
        }

        if (copyLink) {
            copyLink.addEventListener('click', function(e){
                e.preventDefault()
                navigator.clipboard.writeText(window.location.href);
                createCopyBox(copyLink.dataset.copied, e.pageX, e.pageY)
            })
        }

    },
}