export default {
	init: (utils) => {
		const browser_timezone = utils.getCookie('browser_timezone');

		if (!browser_timezone) {
			const browser_time = new Date();
			const GMT_diff = -browser_time.getTimezoneOffset()/60;
			let browser_timezone = "GMT";
			if (GMT_diff > 0) {
				browser_timezone += "+" + GMT_diff;
			} else if (GMT_diff < 0) {
				browser_timezone += "-" + GMT_diff;
			} else {
				browser_timezone += "0";
			}
			utils.setCookie('browser_timezone', browser_timezone, 1000);
		}
	}
}